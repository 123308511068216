import React, { useContext } from 'react'

import { FormValueSetContext, FormValuesContext } from '../../components/Form/FormContext'
import FormValues from '../../components/Form/FormValue'
import UpdateButton from '../../components/buttons/UpdateButton'
import Template from '../../types/templates'
import MultiColorComponent from './MultiColorComponent'
import ColorInputText from './ColorInputText'
import FileUploadComponent from '../../components/Upload/FileUpload'
import AppIcon from '../../components/app_icon/AppIcon'
import axios from 'axios'
import FormFieldTitleComponent from '../../components/edit_ios_page/FormFieldTitle'
import { IMAGE_RESIZER_API } from '../../resources/constants'

interface VideoEditModalPageProps {
    template: Template
    didClickSaveWithInfo: (info: FormValues) => void
}

const VideoEditModalPage: React.FC<VideoEditModalPageProps> = ({ template, didClickSaveWithInfo }) => {
    const formInfoContext = useContext(FormValuesContext)
    const formValues = useContext(FormValuesContext)
    const setFormValues = useContext(FormValueSetContext)
    console.log('REfresssssshed')
    console.log(formValues)

    async function handleIconFileUpload(layerID: string, files: FileList | null) {
        if (files && files?.length > 1) {
            return
        }

        if (files) {
            const newIconUrl = await fileUpload(files[0])
            setFormValues(layerID, newIconUrl)
        }
    }

    const fileUpload = async (file: File) => {
        const presignedResponse = await axios.get('/v1/upload/presignedURL?ext=png')
        const presignedUrl = presignedResponse.data.url
        const fileName = presignedResponse.data.fileName

        const uploadResponse = await axios.put(presignedUrl, file, {
            headers: {
                'Content-Type': file.type,
                'X-Amz-Acl': 'public-read'
            }
        })
        return IMAGE_RESIZER_API + fileName
    }

    return (
        <div className="flex flex-col items-center space-y-8 pt-2 pb-2">
            {template.formLayout.map((formItem) => {
                return (
                    <div key={formItem.id} className="">
                        {formItem.type == 'icon' && formItem.layers != null && formItem.layers.length != null && (
                            <div className="lg:w-[225px] lg:h-[193px] w-[160px] h-[160px] flex flex-col items-center justify-center bg-transparent lg:bg-textInputBg rounded-[22px]">
                                {formItem.displayName != null && <FormFieldTitleComponent title={formItem.displayName ?? ''} subTitle="" />}
                                <AppIcon
                                    iconUrl={formValues[formItem.layers[0]] ?? ''}
                                    handleIconDelete={() => {
                                        if (formItem.layers != null) {
                                            setFormValues(formItem.layers[0], null)
                                        }
                                    }}
                                    handleIconFileUpload={(file) => {
                                        if (formItem.layers != null) {
                                            handleIconFileUpload(formItem.layers[0], file)
                                        }
                                    }}
                                />
                                {/* <img src={formValues[formItem.layers[0]] ?? ''} style={{ width: '250px' }} /> */}
                            </div>
                        )}
                        {formItem.type == 'multi-color' && (
                            <div className="flex flex-col items-center justify-center bg-textInputBg rounded-[22px] p-10">
                                <MultiColorComponent colors={formItem.colors ?? []} />
                            </div>
                        )}
                        {formItem.type == 'colorText' && (
                            <div className="flex flex-col items-center justify-center bg-textInputBg rounded-[22px] p-10">
                                <ColorInputText color={formItem.colorLayer} textLayer={formItem.textLayer ?? ''} displayName={formItem.displayName} />
                            </div>
                        )}
                        {formItem.type == 'tagline' && (
                            <div className="flex flex-col items-center justify-center bg-textInputBg rounded-[22px] p-10">
                                <div className="flex flex-col space-y-2">
                                    {formItem.displayName != null && <p>{formItem.displayName}</p>}
                                    {formItem.title != null && (
                                        <ColorInputText color={formItem.title.colorLayer} textLayer={formItem.title.textLayer ?? ''} displayName={null} />
                                    )}
                                    {formItem.subtitle != null && (
                                        <ColorInputText color={formItem.subtitle.colorLayer} textLayer={formItem.subtitle.textLayer ?? ''} displayName={null} />
                                    )}
                                </div>
                            </div>
                        )}
                        {formItem.type == 'multi-image' && (
                            <div className="flex flex-col w-full items-center justify-center bg-textInputBg rounded-[22px] p-10">
                                <div className="flex flex-col w-full">
                                    {formItem.displayName != null && <p>{formItem.displayName}</p>}
                                    <FileUploadComponent fieldNames={formItem.images ?? []} />
                                </div>
                            </div>
                        )}
                    </div>
                )
            })}
            <UpdateButton
                onClick={() => {
                    didClickSaveWithInfo(formInfoContext)
                }}
                text="SAVE"
            />
        </div>
    )
}

export default VideoEditModalPage
