import React, { useState, ChangeEvent, useRef, useContext, useEffect } from 'react'

import DragAndDrop from './DragDrop'
import UploadItem from './UploadItem'
import FileUploadField from './FilUploadField'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, horizontalListSortingStrategy } from '@dnd-kit/sortable'
import SortableItemComponent from './SortableItem'
import { FormValueSetContext, FormValuesContext } from '../Form/FormContext'
import axios from 'axios'
import { IMAGE_RESIZER_API } from '../../resources/constants'

interface FileUploadComponentProps {
    fieldNames: string[]
}

function generateUUID(): string {
    const hexChars = '0123456789abcdef'
    let uuid = ''

    for (let i = 0; i < 36; i++) {
        if (i === 8 || i === 13 || i === 18 || i === 23) {
            uuid += '-'
        } else if (i === 14) {
            uuid += '4' // Version 4 UUID
        } else if (i === 19) {
            uuid += hexChars[(Math.random() * 4) | 8] // Variant (8, 9, A, or B)
        } else {
            uuid += hexChars[Math.floor(Math.random() * 16)]
        }
    }

    return uuid
}

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({ fieldNames }) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        }),
        useSensor(MouseSensor),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 500,
                tolerance: 5
            }
        })
    )

    const getField = useContext(FormValuesContext)
    const setField = useContext(FormValueSetContext)

    // NSCHRIS TODO
    const hiddenFileInput = useRef<HTMLInputElement>(null)
    const setUploading = async (file: File | null, uploadItem: UploadItem) => {}
    const [key, setKey] = useState(Math.random().toString(36))
    const [isDraggedIn, setIsDraggedIn] = useState<boolean>(false)
    const [uploadItems, setUploadItems] = useState<UploadItem[]>([])

    useEffect(() => {
        const newUploadItems: UploadItem[] = [...uploadItems]
        while (newUploadItems.length < fieldNames.length) {
            const newUUID = generateUUID()
            console.log('Creating new items ' + newUUID)
            newUploadItems.push({
                id: newUUID,
                isUploading: false,
                s3URL: null
            })
        }

        newUploadItems.forEach((item, index) => {
            item.s3URL = getField[fieldNames[index]]
            if (item.s3URL != null) {
                item.isUploading = false
            }
        })

        setUploadItems(newUploadItems)
    }, [fieldNames, getField])

    // NSCHRIS TODO
    const fileValues = {}

    const fieldFileUploaded = (files: FileList, uploadItem: UploadItem) => {
        if (files && files[0]) {
            setUploading(files[0], uploadItem)
        }
    }

    // const fileValsForFields: UploadItem[] = []

    // fieldNamse.forEach((name) => {
    //     const fileVal = fileValues[name]
    //     if (fileVal != null) {
    //         fileValsForFields.push(fileVal)
    //     }
    // })

    function handleDragEnd(event: { active: any; over: any }) {
        const { active, over } = event
        if (active.id === over.id) {
            return
        }
        const newItems = [...uploadItems]
        const oldIndex = uploadItems.findIndex((item) => item.id == active.id)
        const newIndex = uploadItems.findIndex((item) => item.id == over.id)

        const newOrder = arrayMove(newItems, oldIndex, newIndex)

        newOrder.forEach((item, index) => {
            setField(fieldNames[index], item.s3URL)
        })

        setUploadItems(newOrder)
    }

    const upload = async (uploadItem: UploadItem, file: File) => {
        const presignedResponse = await axios.get('/v1/upload/presignedURL?ext=png')
        const presignedUrl = presignedResponse.data.url
        const fileName = presignedResponse.data.fileName
        setUploadItems((uploadItems) => {
            const newUploadItems = [...uploadItems]
            newUploadItems.forEach((item) => {
                if (item.id == uploadItem.id) {
                    item.isUploading = true
                    item.s3URL = null
                }
            })
            console.log(newUploadItems)
            return newUploadItems
        })

        const uploadResponse = await axios.put(presignedUrl, file, {
            headers: {
                'Content-Type': file.type,
                'X-Amz-Acl': 'public-read'
            }
        })
        setUploadItems((uploadItems) => {
            const newUploadItems = [...uploadItems]
            newUploadItems.forEach((item) => {
                if (item.id == uploadItem.id) {
                    item.isUploading = false
                    item.s3URL = IMAGE_RESIZER_API + fileName
                }
            })
            return newUploadItems
        })
        return IMAGE_RESIZER_API + fileName
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = e.target
        if (files != null) {
            handleFiles(files)
        }
    }

    const handleFiles = (files: FileList | null) => {
        console.log('Handling files')
        if (files != null && files.length != 0) {
            const availableUploads = uploadItems.filter((uploadItem) => {
                return (uploadItem.s3URL == null || uploadItem.s3URL == '') && !uploadItem.isUploading
            })

            console.log(availableUploads)

            for (let i = 0; i < files.length; i++) {
                const nextUploadItem = availableUploads.pop()
                if (nextUploadItem == undefined) {
                    break
                }

                upload(nextUploadItem, files[i])
            }
        }
    }
    console.log('Upload items')
    console.log(uploadItems)

    const filteredItems = uploadItems.filter((item) => {
        return (item.s3URL != null && item.s3URL != '') || item.isUploading
    })
    return (
        <div className="w-full">
            {uploadItems.length == 1 ? (
                <FileUploadField
                    uploadItem={uploadItems[0]}
                    filesSelected={fieldFileUploaded}
                    key={uploadItems[0].id}
                    onDelete={() => {
                        if (fieldNames.length > 0) {
                            setField(fieldNames[0], null)
                        }
                    }}
                />
            ) : (
                <div className="flex p-4 space-x-4 bg-base-200 border border-dashed border-colorBorder rounded-sm">
                    <input id="file_input" type="file" onChange={handleFileChange} key={key} style={{ display: 'none' }} ref={hiddenFileInput} multiple />
                    <DragAndDrop
                        onDrop={(files) => {
                            handleFiles(files)
                        }}
                        onDragIn={() => {
                            setIsDraggedIn(true)
                        }}
                        onDragOut={() => {
                            setIsDraggedIn(false)
                        }}
                    >
                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                            <SortableContext items={filteredItems} strategy={horizontalListSortingStrategy}>
                                <div className="flex flex-row gap-5">
                                    {filteredItems.map((field, index) => (
                                        <SortableItemComponent
                                            item={field}
                                            key={field.id}
                                            filesSelected={fieldFileUploaded}
                                            onDelete={() => {
                                                const newUploadItems = [...uploadItems].filter((item) => {
                                                    return item.id != field.id
                                                })

                                                newUploadItems.push({
                                                    id: generateUUID(),
                                                    isUploading: false,
                                                    s3URL: null
                                                })

                                                newUploadItems.forEach((item, index) => {
                                                    if (fieldNames.length > index) {
                                                        setField(fieldNames[index], item.s3URL)
                                                    }
                                                })

                                                setUploadItems(newUploadItems)
                                            }}
                                        />
                                    ))}
                                </div>
                            </SortableContext>
                        </DndContext>
                    </DragAndDrop>
                    {filteredItems.length < fieldNames.length && (
                        <button
                            className="text-brandOrange"
                            onClick={() => {
                                if (hiddenFileInput.current != null) {
                                    hiddenFileInput.current.click()
                                }
                            }}
                        >
                            Upload
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}

export default FileUploadComponent
